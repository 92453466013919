.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.banner {
  font-family: "Cinzel", serif;
  font-size: 3rem;
  font-weight: 700;
  color: #86c232;
  animation-name: fadeIn;
  animation-duration: 4s;
}

.introduction {
  font-size: 1.5rem;
  animation-name: slideIn;
  animation-duration: 2s;

  .inline-link {
    color: #61892f;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 100%;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(10rem);
  }

  to {
    transform: translateX(0);
  }
}
