.references {
  padding: 0 4rem;
}
.contact-container {
  display: flex;
  padding: 2rem 4rem;
  justify-content: flex-start;

  .fab,
  .fas {
    font-size: 7rem;
    margin-right: 2rem;
    color: #86c232;

    &:hover {
      color: #61892f;
    }
  }
}

@media screen and (max-width: 655px) {
  .contact-container {
    align-items: center;
    flex-wrap: wrap;
  }

  .fab,
  .fas {
    font-size: 10rem !important;
  }
}
