// font-family: 'Cinzel', serif;
// font-family: 'Raleway', sans-serif;

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  background-color: #222629;
  color: #999;
}
