.before-image,
.after-image {
  height: 250px;
  width: 350px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;

  &:hover {
    object-fit: contain;
    transform: scale(1.4);
  }
}

.ticket-container {
  padding: 2rem 2rem 0.75rem 2rem;
  display: flex;
  background-color: rgba(71, 75, 79, 0.4);
  box-shadow: 10px 5px 5px #222;
  border-radius: 24px;
  margin: 20px;

  .ticket-item-container {
    display: flex;
    flex-direction: column;
    flex-grow: 3;

    .comparison-pics-container {
      display: flex;

      margin-top: 15px;

      .before-picture {
        margin-right: 20px;
      }

      h5 {
        margin-bottom: 2px;
        color: #474b4f;
        font-style: italic;
      }
    }
  }

  .metric-container {
    margin-left: 4rem;

    h4 {
      font-family: "Cinzel", serif;
      color: #86c232;
    }

    .metric-image {
      width: 550px;
      height: auto;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
      margin-bottom: 15px;

      &:hover {
        object-fit: contain;
        transform: scale(1.4);
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .ticket-container {
    flex-direction: column;

    .metric-container {
      margin-left: 0;
      margin-top: 15px;
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 855px) {
  .comparison-pics-container {
    flex-direction: column;
  }

  .before-image,
  .after-image {
    height: 150px;
    width: 250px;
    object-fit: cover;
    object-position: center;
  }
} ;
