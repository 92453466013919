.nav-bar {
  padding: 25px;

  .brand {
    font-family: "Cinzel", serif;
    font-size: 2rem;
    &:hover {
      color: #86c232 !important;
    }
  }

  .nav-link {
    margin-left: 5px;
    padding-right: 20px;
    font-size: 1.2rem;
    color: #61892f !important;

    &:hover {
      color: #999 !important;
    }
  }
}

//Mobile styling:
@media screen and (max-width: 991px) {
  .dropdown {
    text-align: right;
    position: fixed;

    top: 20px;
    right: 45px;
    background-color: rgba(34, 38, 41, 0.9);
  }

  a.nav-link {
    margin: 5px 35px;
  }
}

@media (max-width: 514px) {
  .nav-bar {
    padding-left: 0;
  }
}
