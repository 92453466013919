.project-item-container {
  display: flex;
  background-color: rgba(71, 75, 79, 0.4);
  box-shadow: 10px 5px 5px #222;
  border-radius: 24px;
  max-width: 1000px;
  margin: 20px auto;
  padding: 1rem 1.5rem;

  .thumbnail {
    height: 250px;
    width: 350px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
  }

  .description-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 0 0 0px 1rem;

    a {
      box-shadow: 3px 3px 5px #222;
      margin-right: 25px;
      width: 200px;

      &:hover {
        background-color: #86c232;
        color: #222629;
      }
    }

    .github-btn-icon {
      margin-right: 5px;
      font-size: 1rem;
    }
  }

  p {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .project-item-container {
    flex-direction: column;
    align-items: flex-start;
    max-width: fit-content;
    margin: 20px auto;

    img {
      width: 420px;
      height: 320px;
    }

    .description-container {
      padding: 0;
      margin: 20px 0;
      max-width: 450px;
    }
  }
}

@media screen and (max-width: 855px) {
  .project-item-container {
    margin: 20px;

    .thumbnail {
      height: 200px;
      width: 300px;
    }
  }
  a {
    width: 100% !important;
    margin-bottom: 10px;
  }
}
