.header-title {
  font-family: "Cinzel", serif;
  font-size: 4rem;
  color: #86c232;
  margin-top: 2.5rem;
}

.sub-title {
  font-size: 2rem;
}

h6 {
  color: #474b4f;
  font-style: italic;
}

.left {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 2rem;
}

.center {
  text-align: center;
}

@media screen and (max-width: 514px) {
  .header-title {
    margin-top: 0;
    font-size: 3rem;
  }
  .center {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 20px;
  }
}
