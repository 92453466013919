.about-me-container {
  display: inline-block;
  padding: 0 40px;
  font-size: 1.2rem;
  margin-bottom: 6rem;

  .inline-link {
    color: #61892f;
    text-decoration: none;

    &:hover {
      color: #fff;
    }
  }
}

.certfications-container {
  padding: 0 40px;

  .certfications {
  font-family: "Cinzel", serif;
  font-size: 2rem;
  color: #86c232;
  }

  .cert-container {
    margin: 2rem 0;
  }

  .cert-image {
    border-radius: 12px;
    height: 200px;
    width: auto;
  }

  .individual-cert {
    display: flex;

    .cert-description {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
      margin-left: 20px;
    }
  }
}
